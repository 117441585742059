import type { ComponentProps, ComponentType, SvelteComponent } from 'svelte';
import { modal } from '$stores/modalStore';
import Alert from '$components/modalContent/Alert.svelte';
import Confirm from '$components/modalContent/Confirm.svelte';
import Info from '$components/modalContent/Info.svelte';
import Prompt from '$components/modalContent/Prompt.svelte';
// import Custom from '$components/modalContent/Custom.svelte';
import type { Modal } from '$components/basic';

/**
 * Open Modal Window with Alert Component with Confirm Button.
 * @param props ComponentProps<Alert> | msg: string
 * @param modalProps
 * @returns Promise<any | null>
 * @example
 * ```
 * const rt1 = await alert({ msg: '안녕하십니까?' }, { outsideclose: false, permanent: true, size: 'xs' });
 * const rt2 = await alert('안녕하십니까?');
 * ```
 */
export function alert(props: ComponentProps<Alert> | string, modalProps?: ComponentProps<Modal>) {
    if (typeof props === 'string') {
        props = { msg: props } as ComponentProps<Alert>;
    }
    return modal.open(Alert, { ...props }, { size: 'xs', ...modalProps }, 'alert');
}

/**
 * Open Modal Window with Confirm Component with Confirm Button and Cancel Button.
 * @param props ComponentProps<Confirm> | msg: string
 * @param modalProps ComponentProps<Modal>
 * @returns Promise<any | null>
 * @example
 * ```
 * const rt1 = await confirm({ msg: '안녕하십니까?' }, { outsideclose: false, permanent: true, size: 'xs' });
 * const rt2 = await confirm('안녕하십니까?');
 * ```
 */
export function confirm(props: ComponentProps<Confirm> | string, modalProps?: ComponentProps<Modal>) {
    if (typeof props === 'string') {
        props = { msg: props } as ComponentProps<Confirm>;
    }
    return modal.open(Confirm, { ...props }, { size: 'xs', ...modalProps }, 'confirm');
}

/**
 * Open Modal Window with Prompt Component with Input Field, Confirm Button and Cancel Button.
 * @param props ComponentProps<Prompt> | msg: string
 * @param modalProps ComponentProps<Modal>
 * @returns Promise<any | null>
 * @example
 * ```
 * const rt1 = await prompt({msg: '이것은 prompt 입니다'})
 * ```
 */
export function prompt(props: ComponentProps<Prompt> | string, modalProps?: ComponentProps<Modal>) {
    if (typeof props === 'string') {
        props = { msg: props } as ComponentProps<Prompt>;
    }
    return modal.open(Prompt, { ...props }, { size: 'sm', ...modalProps }, 'prompt');
}

/**
 * Open Modal Window with Info Component with Confirm Button.
 * @param props ComponentProps<Info> | msg: string
 * @param modalProps ComponentProps<Modal>
 * @returns Promise<any | null>
 * @example
 * ```
 * const rt1 = await info({ msg: '안녕하십니까?' }, { outsideclose: false, permanent: true, size: 'xs' });
 * const rt2 = await info('안녕하십니까?');
 * ```
 */
export function info(props?: ComponentProps<Info> | string, modalProps?: ComponentProps<Modal>) {
    if (typeof props === 'string') {
        props = { msg: props } as ComponentProps<Info>;
    }
    return modal.open(Info, { ...props }, { size: 'lg', hasBorderHeader: true, hasBorderFooter: true, ...modalProps }, 'info');
}

/**
 * Open Modal Window with Custom Component for Modal Body
 * @param component CustomComponent
 * @param props ComponentProps<CustomComponent> | msg: string
 * @param modalProps ComponentProps<Modal>
 * @returns Promise<any | null>
 * @example
 * ```
 * const rt1 = await custom(Alert, { msg: '안녕하십니까?' }, { outsideclose: false, permanent: true, size: 'xs' });
 * const rt2 = await custom(Alert, '안녕하십니까?');
 * const rt3 = await custom(CustomComponent, { value: bindingValue, msg: '안녕하십니까?', ... }, {size: 'xl} });
 * ```
 */
export function custom<T extends SvelteComponent>(component: ComponentType<T>, props?: ComponentProps<T> | string, modalProps?: ComponentProps<Modal>) {
    if (typeof props === 'string') {
        props = { msg: props } as ComponentProps<T>;
    }
    return modal.open(component, { ...props } as ComponentProps<T>, { size: 'xs', ...modalProps }, 'custom');
}

/**
 * Open Custom Component for FullModal
 * @param component CustomComponent
 * @param props ComponentProps<CustomComponent>
 * @param modalProps ComponentProps<FullModal>
 * @returns Promise<any | null>
 * @example
 * ```
 * const rt = await fullcustom(CustomComponent);
 * ```
 */
export function fullcustom<T extends SvelteComponent>(component: ComponentType<T>, props?: ComponentProps<T>) {
    return modal.open(component, { ...props } as ComponentProps<T>, {}, 'fullcustom');
}

/**
 * 열려있는 모달을 일괄적으로 false로 resolve 처리해서 닫습니다.
 */
export function resolveAllModalToFalse() {
    modal.resolveAllToFalse();
}
